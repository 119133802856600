import Analytics from '../../SiteNav/analytics.js';

import './utility.less';
import { parseQuery } from '@cwp/utils';

/*
 * Careers Site Header (Utility Nav)
 */
class CareersHeader extends HTMLElement {
  connectedCallback() {
    this.shadow = this.attachShadow({ mode: 'open' });
    let currentShadow = this.shadow;

    this.template = this.querySelector('#header-template');
    if (this.template && this.template.content) {
      this.shadow.appendChild(this.template.content);
      const iconGlobe = this.shadow.querySelector('.icon-globe');
      const iconTarget = this.shadow.querySelector('.country-menu');
      
      const togglePopover = (e) => {
        e.preventDefault();

        iconTarget.classList.toggle('is-visible');

        iconTarget.getAttribute('aria-hidden') === 'true'
          ? iconTarget.setAttribute('aria-hidden', 'false')
          : iconTarget.setAttribute('aria-hidden', 'true');

        iconGlobe.getAttribute('aria-expanded') === 'true'
          ? iconGlobe.setAttribute('aria-expanded', 'false')
          : iconGlobe.setAttribute('aria-expanded', 'true');

        e.stopPropagation();
      };

      iconGlobe.addEventListener('click', togglePopover, false);

      const hamburgerMenu = currentShadow.querySelector('.amp-hamburger');
      const exLink = currentShadow.querySelector('.utilityNav')

      /* Mobile view external link */
      exLink.addEventListener('click', (event) => {
        const message = event.target && event.target.getAttribute('data-analytics');
        const href = event.target && event.target.getAttribute('href');
        const navItem = event.target.offsetParent;
        const navClass = event.target.classList;
        let siteId = ''
        if (href && href.indexOf('&siteid') > -1) {
          siteId = parseQuery(href).siteid.split('#')[0];
        }
        let type = navItem.classList.contains('careers-utilityNav-item')
                ? 'header'
                : navClass.contains('careers-mainNav-link')
                ? 'nav'
                : 'navtray';

        switch (message.toLowerCase()) {
          case 'contact':
            try {
              const customAnalytics = new Analytics();
              customAnalytics.setSatelliteTrack('contactARecruiterPixel', href);
            } catch (e) {}
            break;
          case 'apply':
            try {
              const customAnalytics = new Analytics();
              customAnalytics.ping(message, href, siteId, type);
            } catch(e) {}
            break;
          default:
            break;
        }
      })
      hamburgerMenu.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        window.dispatchEvent(new CustomEvent('menuclick', { detail: 'placeholder' }));
      });

      

      // SiteNav Header Analytics
      const siteNavHeaderLinks = document.querySelectorAll('a[data-tracking="Link"]');
      
      siteNavHeaderLinks &&
        siteNavHeaderLinks.length > 0 &&
        siteNavHeaderLinks.forEach((link) => {
          link &&
            link.addEventListener('click', (event) => {
              const message = event.target && event.target.getAttribute('data-analytics');
              const href = event.target && event.target.getAttribute('href');
              let siteId = '';
              if (href.indexOf('&siteid') > -1) {
                siteId = parseQuery(href).siteid.split('#')[0];
              }
              const type = 'header';

              switch (message.toLowerCase()) {
                // In render site, it shows as Apply but Analytics wanted the text to show as Search and apply as the other ones on the site
                // When you view the source code for Apply, the data-analytics is set to Search and apply
                case 'contact a recruiter':
                  try {
                    const customAnalytics = new Analytics();
                    customAnalytics.setSatelliteTrack('contactARecruiterPixel', href);
                  } catch (e) {}
                  break;
                
                case 'search and apply':
                  try {
                    const customAnalytics = new Analytics();
                    customAnalytics.setSatelliteTrack('careersNavigationClicks', href);
                  } catch (e) {}
                  break;
                default:
                  try {
                    const analytics = new Analytics();
                    analytics.ping(message, href, siteId, type);
                  } catch (e) {}
                  break;
              }
            });
        });
    }
  }
}

window.customElements.define('careers-header', CareersHeader);
