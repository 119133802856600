import Analytics from '../../SiteNav/analytics.js';
import { handleAnalyticsDataAttrClick } from '../../SiteNav/util.js';
import { parseQuery } from '@cwp/utils';

import './navigation.less';

const CONSTs = {
  PRODUCTION: 'production',
  QA: 'qa',
  DEV: 'dev',
  LOCAL: 'local',
  PREVIEW: 'preview',
  UNKNOWN: 'unknown',
  POPOVER_CLASS: 'Popover',
  IS_VISIBLE_CLASS: 'is-visible'
};

let ua = window.navigator.userAgent.toLowerCase();

// IE user-agent strings contain "MSIE" and/or "Trident" (code name for IE's rendering engine)
const _isIE = ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1;

/*
 * Careers Site Navigation
 */
class CareersMainNav extends HTMLElement {
  constructor() {
    super();

    this.userTemplate = '';

    this.currentHostName = window.location.hostname;
    this.currentPort = window.location.port;
    this.currentURL = window.location.pathname;
    this.currentEnvironment = this.getEnvironment();

    this.selectedNav = this.getSelectedTab();

    this.shadow = this.attachShadow({ mode: 'open' });
  }

  getEnvironment() {
    const isLocal =
      /localhost|127\.0\.0\.1/i.test(this.currentHostName) || this.currentPort === '8080';
    const isAmeriprisePublicSite =
      /(www|www1)(\d*|-ws1|preview|demo)?(\.qa.stage|\.qa|.dev|)?\.ameriprise\.com/.test(
        this.currentHostName
      );
    const isAnyLower =
      /dev|ws1.dev|qa|ws1.qa|qa.stage|preview|127\.0\.0\.1|local/i.test(this.currentHostName) ||
      this.currentPort === '8080';
    const isAmpLower = isAmeriprisePublicSite && isAnyLower;
    const isProd = !isAnyLower && !isAmpLower;
    const isDev = /dev|ws1.dev\./i.test(this.currentHostName);
    const isQa = /qa|ws1.qa\./i.test(this.currentHostName);
    const isPreview = /preview\./i.test(this.currentHostName);

    switch (true) {
      case isProd:
        return CONSTs.PRODUCTION;
      case isLocal:
        return CONSTs.LOCAL;
      case isQa:
        return CONSTs.QA;
      case isDev:
        return CONSTs.DEV;
      case isPreview:
        return CONSTs.PREVIEW;
      default:
        return CONSTs.UNKNOWN;
    }
  }

  getSelectedTab() {
    let selectedNav = 'Home';
    let combinedURLValues = [];
    const siteNavMenuConfig = document.querySelector('#careersSiteNavMenuContainer');
    if (siteNavMenuConfig && siteNavMenuConfig.innerText) {
      const siteNavMenuSettings = JSON.parse(siteNavMenuConfig.innerText);
      window.CAREERSSITENAVJSON = siteNavMenuSettings.CAREERSSITENAVJSON;
      siteNavMenuSettings.CAREERSSITENAVJSON &&
        siteNavMenuSettings.CAREERSSITENAVJSON.forEach((item) => {
          combinedURLValues.push(item);
        });
    } else {
      combinedURLValues = [
        { key: 'experienced-financial-advisors', label: 'Experienced Advisors' },
        { key: 'new-financial-advisors', label: 'New Advisors' },
        { key: 'corporate', label: 'Corporate Professionals' },
        { key: 'students', label: 'Students' },
        { key: 'redesign', label: 'Redesign' }
      ];
    }

    if (
      this.currentURL &&
      this.currentEnvironment !== CONSTs.UNKNOWN &&
      this.currentEnvironment !== CONSTs.PREVIEW
    ) {
      const currentURLSplit = this.currentURL.split('/').filter(function (item) {
        return item !== 'careers' && item !== 'redesign' && item !== CONSTs.PREVIEW;
      });

      if (currentURLSplit && currentURLSplit.length && currentURLSplit.length > 1) {
        const currentLevelOneMenuFromURL =
          currentURLSplit[this.currentEnvironment !== CONSTs.LOCAL ? 1 : 3];
        if (currentLevelOneMenuFromURL && currentLevelOneMenuFromURL.length > 1) {
          combinedURLValues.forEach((item) => {
            if (item.key === currentLevelOneMenuFromURL) {
              selectedNav = item.label;
            }
            if (currentLevelOneMenuFromURL === "field-leader") {
              selectedNav = "Experienced Advisors"
            }
          });
        }
      }
    }

    return selectedNav;
  }

  // firstTime = first time page loads
  setCurrentNav(selectedNav, menuLevel, navLevel, menuLevelNext, navLevelNext, firstTime) {
    const this1 = this;

    const levelClass = menuLevel > 1 ? 'nav.careers-nav-level-two' : 'nav.careers-nav-level-one';
    const navLevelClass = navLevel > 1 ? '.careers-childNav-item' : '.careers-mainNav-item';

    const navItems = this.shadow.querySelectorAll(
      '.careers-nav-inner' + ' ' + levelClass + ' ' + '.careers-mainNav' + ' ' + navLevelClass
    );

    if (navItems) {
      Array.from(navItems).forEach((navItem) => {
        const navLinkClass =
          menuLevel > 2 ? '.careers-childNav-link' : '.careers-mainNav-link span';
        const navLink = navItem.querySelector(navLinkClass);

        if (navLink) {
          const navItemClass =
            menuLevel > 2 ? 'careers-childNav-item--current' : 'careers-mainNav-item--current';

          if (
            navLink.textContent &&
            selectedNav &&
            navLink.textContent.toLowerCase() === selectedNav.toLowerCase() &&
            !navItem.classList.contains(navItemClass)
          ) {
            navItem.classList.add(navItemClass);
            navItem.classList.add('is-selected');
          } else {
            navItem.classList.remove(navItemClass);
            navItem.classList.remove('is-selected');
          }

          if (firstTime) {
            if (
              navItem.classList.contains('careers-mainNav-item--hasDropdown') ||
              navItem.classList.contains('careers-childNav-item--hasDropdown')
            ) {
              /* IE HACK BEGIN */
              /* Could not get preventDefault() (below) to work on A tags in IE */
              if (_isIE) {
                const navLinkClass2 =
                  menuLevel > 2 ? '.careers-childNav-link' : '.careers-mainNav-link';
                const navLink2 = navItem.querySelector(navLinkClass2);

                const navLinkHref = navLink2.getAttribute('href');

                navLink2.setAttribute('href', '');

                navLink2.addEventListener('click', (event) => {
                  if (!this1.navMenu.classList.contains(CONSTs.POPOVER_CLASS)) {
                    event.preventDefault();
                    event.stopPropagation();

                    window.location = navLinkHref;
                  }
                });
              }
              /* IE HACK END */

              // Nav buttons
              navItem.addEventListener('click', (event) => {
                // Only do this click event if we're in "hamburger menu"
                if (this1.navMenu.classList.contains(CONSTs.POPOVER_CLASS)) {
                  if (event.target.classList.contains('careers-grandchildNav-link')) {
                    return;
                  }

                  event.preventDefault();
                  event.stopPropagation();

                  this1.setCurrentNav(navLink.textContent, menuLevelNext, navLevelNext);
                }
              });
            }
          }
        }
      });

      if (firstTime && menuLevel > 1) {
        // Back button
        const navLinkBack = this.shadow.querySelector(
          '.careers-nav-level-two .careers-mainNav-backButton'
        );
        if (navLinkBack !== null) {
          navLinkBack.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            this.resetCurrentNav();
          });
        }
      }

      if (!firstTime && menuLevel > 1) {
        this.navMenu.classList.remove('careers-nav-level-one');
        this.navMenu.classList.add('careers-nav-level-two');
      } else {
        this.navMenu.classList.remove('careers-nav-level-two');
        this.navMenu.classList.add('careers-nav-level-one');
      }
    }
  }

  resetCurrentNav() {
    // this.setCurrentNav('', level 3, level 2)
    this.setCurrentNav('', 3, 2); // for desktop view
    this.setCurrentNav('', 2, 1); // for desktop view
    this.setCurrentNav('', 1, 2); // for mobile view
  }

  resized(firstTime) {
    // Add `Popover` class to Nav if `small` viewport, otherwise remove that class and `is-visible`
    if (window.innerWidth > 1024) {
      this.navMenu.classList.remove(CONSTs.POPOVER_CLASS);
      this.navMenu.classList.remove(CONSTs.IS_VISIBLE_CLASS);
    } else {
      let reset = false;

      if (!firstTime && !this.navMenu.classList.contains(CONSTs.POPOVER_CLASS)) {
        reset = true;
      }

      this.navMenu.classList.add(CONSTs.POPOVER_CLASS);

      if (reset) {
        this.resetCurrentNav();
      }

      this.nav.classList.remove('sticky');
    }
  }

  clicked() {
    if (this.navMenu.classList.contains(CONSTs.IS_VISIBLE_CLASS)) {
      this.navMenu.classList.remove(CONSTs.IS_VISIBLE_CLASS);

      this.resetCurrentNav();
    } else {
      this.navMenu.classList.add(CONSTs.IS_VISIBLE_CLASS);
    }
  }

  connectedCallback() {
    this.template = this.querySelector('#main-nav-template');

    if (this.template && this.template.content) {
      this.shadow.appendChild(this.template.content);

      this.utilityNav = this.shadow.querySelectorAll('.careers-utilityNav');

      this.nav = this.shadow.querySelector('.careers-nav');
      this.navMenu = this.shadow.querySelector('.careers-nav-inner');
      this.navSearchApply = this.shadow.querySelector('.nav-search-apply');
      this.navSearchApplyButton = this.shadow.querySelector('.nav-search-apply-button');
      this.navCallUs = this.shadow.querySelector('.nav-call-us');
      this.navFindARecruiter = this.shadow.querySelector('.nav-find-a-recruiter');
      this.enableStickyNav = this.shadow.querySelector('.enable-sticky-nav');

      // Initial load of page: display "underline" on nav item for current page and set up mobile menu
      this.setCurrentNav(this.selectedNav, 1, 1, 2, 1, true);

      this.setCurrentNav('', 3, 2, 3, 2, true);

      // Initial load of page - display "hamburger menu" if small/medium viewport
      this.resized(true);

      let resizeTimer;
      let clickTimer;

      // If window is resized - display "hamburger menu" if small/medium viewport
      window.addEventListener('resize', (event) => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          this.resized();
        }, 20);
      });

      // When "hamburger menu" icon is clicked, display menu
      window.addEventListener('menuclick', (event) => {
        clearTimeout(clickTimer);
        clickTimer = setTimeout(() => {
          this.clicked();
        }, 20);
      });

      let scrollPosition = 0;
      let lastScrollPosition = 0;
      let ticking = false;

      if (!_isIE) {
        window.addEventListener('scroll', (e) => {
          scrollPosition = window.pageYOffset;
          if (!ticking) {
            window.requestAnimationFrame(() => {
              if (window.innerWidth > 1024 && this.enableStickyNav != null) {
                if (scrollPosition > 72 && scrollPosition <= lastScrollPosition) {
                  this.nav.classList.add('sticky');
                  this.navSearchApply &&
                    this.navSearchApply !== null &&
                    this.navSearchApply.classList.add('u-hidden');
                  this.navSearchApplyButton &&
                    this.navSearchApplyButton !== null &&
                    this.navSearchApplyButton.classList.remove('u-hidden');
                  this.navFindARecruiter &&
                    this.navFindARecruiter !== null &&
                    this.navFindARecruiter.classList.remove('u-hidden');
                  this.navCallUs && this.navCallUs.classList.remove('u-hidden');
                } else {
                  this.nav.classList.remove('sticky');
                  this.navSearchApply &&
                    this.navSearchApply !== null &&
                    this.navSearchApply.classList.remove('u-hidden');
                  this.navSearchApplyButton &&
                    this.navSearchApplyButton !== null &&
                    this.navSearchApplyButton.classList.add('u-hidden');
                  this.navFindARecruiter &&
                    this.navFindARecruiter !== null &&
                    this.navFindARecruiter.classList.add('u-hidden');
                  this.navCallUs && this.navCallUs.classList.add('u-hidden');
                }
              }

              lastScrollPosition = scrollPosition;

              ticking = false;
            });

            ticking = true;
          }
        });
      }

      /* Analytics click event - MENU */
      const headerNavContent = this.shadow.querySelector('.careers-nav');
      const navLinks = headerNavContent && headerNavContent.getElementsByTagName('a');

      navLinks &&
        navLinks.length > 0 &&
        navLinks.forEach((link) => {
          link &&
            link.addEventListener('click', (event) => {
              const message = event.target && event.target.getAttribute('data-analytics');
              const navClass = event.target.classList;
              const navItem = event.target.offsetParent;
              const href = event.target && event.target.getAttribute('href');
              let siteId = '';
              if (href && href !== null && href.indexOf('&siteid') > -1) {
                siteId = parseQuery(href).siteid.split('#')[0];
              }

              if (
                window.innerWidth <= 1024 &&
                (navItem.classList.contains('careers-mainNav-backButton') ||
                  (navItem.classList.contains('careers-mainNav-item--hasDropdown') &&
                    navItem.offsetParent.classList.contains('careers-nav-level-one')) ||
                  navItem.classList.contains('careers-childNav-item--hasDropdown'))
              ) {
                return;
              }

              let type = navItem.classList.contains('careers-utilityNav-item')
                ? 'header'
                : navClass.contains('careers-mainNav-link')
                ? 'nav'
                : 'navtray';
              
              switch (message.toLowerCase()) {
                case 'new advisors':
                case 'corporate professionals':
                case 'students':
                case 'search and apply':
                case 'call us':
                  try {
                    const customAnalytics = new Analytics();
                    customAnalytics.setSatelliteTrack('callUsPixel', href);
                  } catch (e) {}
                  break;
                case 'contact a recruiter':
                  try {
                    const customAnalytics = new Analytics();
                    customAnalytics.setSatelliteTrack('contactARecruiterPixel', href);
                  } catch (e) {}
                  break;
                case 'find a recruiter':
                  try {
                    const customAnalytics = new Analytics();
                    customAnalytics.setSatelliteTrack('careersNavigationClicks', href);
                  } catch (e) {}
                  break;
              }
              try {
                const analytics = new Analytics();
                analytics.ping(message, href, siteId, type);
              } catch (e) {}
            });
        }); // close menu analytics

      /* Analytics click event - BREADCRUMB */
      const headerBreadcrumbContent = this.shadow.querySelector('.careers-breadcrumb');
      const breadcrumbLinks =
        headerBreadcrumbContent && headerBreadcrumbContent.getElementsByTagName('a');

      breadcrumbLinks &&
        breadcrumbLinks.length > 0 &&
        breadcrumbLinks.forEach((link) => {
          let type = 'breadcrumb';
          handleAnalyticsDataAttrClick(link, type);
        }); // close breadcrumb analytics
    }
  }
}

window.customElements.define('careers-menu', CareersMainNav);
